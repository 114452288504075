import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { Icon } from '@components'
import { SITE_URL } from '@config/config'
import { AuthPageHeader } from '@pages'
import * as routes from '@src/routes'
import { useAnalyticsSender } from '@src/utils'

import s from './SuccessPayment.module.scss'

export default function Component() {
  const { t } = useTranslation(['billing', 'other'])
  const navigate = useNavigate()

  useAnalyticsSender()

  const backHandler = () => {
    navigate(routes.BILLING, {
      replace: true,
    })
  }

  return (
    <div className={s.modalBg}>
      <AuthPageHeader onLogoClick={backHandler} />
      <div className={s.modalBlock}>
        <div className={s.modalTopBlock}>
          <Icon name="SuccessPay" />
          <div className={s.approved}>{t('Payment approved')}</div>
          <div className={s.completed}>{t('Payment was completed successfully')}</div>
        </div>

        <div className={s.linksBlock}>
          <a className={s.link} href={`${SITE_URL}/`}>
            {t('Back to site')}
          </a>
          <Link className={s.link} to={routes.BILLING}>
            {t('Back to billing')}
          </Link>
        </div>
      </div>
    </div>
  )
}
