// ------- DEV -----
// export const BASE_URL = 'https://test.hard-soft.cf'
// export const RECAPTCHA_KEY = '6LdIo4QeAAAAAGaR3p4-0xh6dEI75Y4cISXx3FGR'

// ---- PRODUCTION ------
// ---- omro ----
export const BASE_URL = 'https://api.omro.host'
export const RECAPTCHA_KEY = '6LeTB5cjAAAAANygkgjOmiFMbO-RjMiRCq_r4PfV'
export const API_URL = 'https://api-ru.server-panel.net/v1'
export const SITE_URL = 'https://omro.host'
export const PRIVACY_URL = `${SITE_URL}/privacy-policy`
export const OFERTA_URL = `${SITE_URL}/oferta`

// ---- Zomro ----
// export const BASE_URL = 'https://api.zomro.com'
// export const RECAPTCHA_KEY = '6LduamkfAAAAAKOy-2RHeTh6GLCydKT8fhw--KuW'
